import React, { useState, useEffect, useContext, useRef } from "react";
import { StoreContext } from '../../store/store';
import Button from '../Button/Button';
import Input from '../Input/Input';
import Link from '../Link/Link';
import { text, encryptPassword, countryMarket } from "../../utils/global";
import { ServiceRequest, handleLink } from '../../utils/Services';
import useForm from "./useForm";
import Identifier from '../Identifier/Identifier';
import Checkbox from '../Checkbox/Checkbox';
import OrSeperate from '../OrSeperate/OrSeperate';
import InputOTP from '../InputOTP/InputOTP';
import VFLogger from '../../utils/Logger';
import analyticsTealium from "../../utils/Analytics/analytics";
import ReCAPTCHA from "react-google-recaptcha";
import { getPageName } from "../../constants/tealiumMapper";

import {
	SPLASH_LOAD
} from "../../constants/actionTypes";

const Authenticate = props => {
	const recaptchaRef = useRef();

	let { state, dispatch } = useContext(StoreContext);
	let buttons = [];
	let identifier = state.data.identifier.type === 'tel' ? '+' + state.data.identifier.value : state.data.identifier.value;
	let identifierLabel = state.data.identifier.type === 'tel' ? 'msisdn' : state.data.identifier.type;
	let passwordTag, forgotUsername = false, forgotPassword = false, register = false;
	const [passwordType, setPasswordType] = useState("password");
	const [passwordIcon, setPasswordIcon] = useState("show");
	let identifierDisplay = false, identifierTag = false, rememberMyUsernameCheckbox = false, rememberMeCheckbox = false, OrSeperateTag = false, dpaPin = false;
	let market = undefined;
	const [isRememberUsername, setRememberUsername] = useState((state.data.fields !== undefined && state.data.fields.rememberMyUsernameCheckbox !== undefined) ? (state.data.fields.rememberMyUsernameCheckbox[0].value === true ? 'checked' : "") : false);
	const [isRememberMe, setRememberMe] = useState(state.data.remember_me && state.data.remember_me.value && state.data.remember_me.value === true ? true : false);
	const [OTP, setOTP] = useState("");

	const [isInfoShown, setInfoShown] = useState(false);
	const { trackPageEvent }= analyticsTealium();

	const {
		errors,
		values,
		identifierTitle,
		identifierPadding,
		titleClassName,
		setTitleClassName,
		paddingClassName,
		setPaddingClassName,
		setErrors,
		buttonType,
		setButtonType,
		handleSubmit,
		handleChange,
		resetValues,
		handleBlur
	} = useForm((e) => onSubmitWithReCAPTCHA(e));

	const BRIDGE = "DataActivationController";
	const DONE_SELECTED_CALLBACK = "doneSelected";

	let myVodafoneGuidanceText = false;

	function getBridge(){
        let bridge = window[BRIDGE];

        if (!bridge) {
            //try some exotic way to get it

            try {
                // eslint-disable-next-line
                bridge = DataActivationController;
            } catch (error) {
                //skip this method and try another one
            }

            if (!bridge) {
                try {
                    // eslint-disable-next-line no-undef
                    bridge = global[BRIDGE];
                } catch (err) {
                    //skip this method and try another one
                }
            }

            if (!bridge) {
                try {
                    // eslint-disable-next-line
                    bridge = eval(BRIDGE);
                } catch (err) {
                    //cannot find bridge
                }
            }
        }
        return bridge;
    }

    function hasCallback(){
        let bridge = getBridge();

        if( bridge ){
            return !! bridge[DONE_SELECTED_CALLBACK];
        }

        return false;
    }

	// Detect AppleWatch mobile app
	const appleWatchApp = hasCallback();
	console.log( "Apple Watch App: " + appleWatchApp );

	//DOM load event
	useEffect(() => {
		//On Error Clear the screen
		if (state.errorObj && state.errorObj.msg) {
			setOTP('');
			if (state.data.fields !== undefined && state.data.fields.pin !== undefined) {
				// Set the first PIN box to active
				if(document.getElementById("otpInputWrap")){
					document.getElementById("otpInputWrap").firstElementChild.classList.remove("initial_state");
				}
			}
			resetValues();
			setRememberUsername(false);
			setRememberMe(false);
		}

		// reset reCAPTCHA
		if( recaptchaRef.current ){
			recaptchaRef.current.reset();
		}
	}, [state.errorObj]);

	// Get Identifier
	if (state.data.identifier) {
		let country = state.data.nvMarket !== undefined ? state.data.nvMarket.toUpperCase() : undefined;
		market = countryMarket(state.data.nv, country);
		//Identifier display section
		if (identifier !== undefined) {
			identifierDisplay = <div className="user-name">
				<div className="section-form-label">{text('label.for.identifier.' + identifierLabel)}</div>
				<div className="section-form-label notyoulink">
					{identifier} &nbsp;
					<a id="notyoulink" onClick={() => {trackPageEvent(getPageName(state.data.context), {eventCategory: "link", eventLabel: text('not.received.pin.edit.username.label') }), handleLink(state.data.links['urn:vodafoneid:changeidentifier'], props.RequestCallBack)}}>{text('not.received.pin.edit.username.label')}</a>
				</div>
			</div>
		} else {
			identifierTag =
				<>
					{state.data.showCredentialsHelpText && state.data.identifier && state.data.identifier.type && state.data.identifier.type === "username" &&
						<>
							<div className="info-button-icon" onClick={() => setInfoShown(true)}></div>

							{isInfoShown &&
								<div className="toast-message-overlay"onClick={() => setInfoShown(false)}>
									<div className="toast-message" onClick={(e) => {e.stopPropagation()}}>
										<div className="toast-message-header">
											<button className="button icon close" id="close" type="button" onClick={() => setInfoShown(false)}></button>
										</div>
										<div className="toast-message-title">{text('credentials.help.text.title')}</div>
										<div className="toast-message-text">{text('credentials.help.text')}</div>
									</div>
								</div>
							}
						</>
					}

					<Identifier identifierTitle={identifierTitle} className={errors.identifierClass} identifierPadding={identifierPadding} market={market}
						handleChange={handleChange}
						handleKey={handleKeyPress}
						handleBlur={handleBlur}
						value={values.identifier}
						errMsg={errors.identifierMsg}
						dataTestID={"USERNAME"}
						id={state.page_look === "mva" ? "USERNAME" : ""}
					></Identifier>
					</>;
		}
	}

	//Buttons / links
	if (state.data.links !== null || state.data.links !== undefined) {
		//Submit button (Continue)
		if (state.data.links['urn:vodafoneid:authenticate']) {
			buttons.push(
				<div className="btn-wrapper" key="btn1">
					<Button
						type={buttonType}
						label={text('submit.button.label')}
						id="continueButton"
						dataTestID={"CONTINUEBUTTON"}
						handleClick={ (event) => {
							trackPageEvent(getPageName(state.data.context), {eventCategory: "button", eventLabel: text('submit.button.label') }),
							handleSubmit(event)}}
					/>
				</div>
			);
		}

		// Add "Forgot username" link
		if( state.data.links && state.data.links["urn:vodafoneid:forgotUsername"] ){
			const forgotUsernameLink = state.data.links['urn:vodafoneid:forgotUsername'];
			forgotUsername = <Link id="forgotUsername" handleLink={() => { trackPageEvent(getPageName(state.data.context), {eventCategory: "link", eventLabel: text('forgot.username.link.text') }), handlerLinks(forgotUsernameLink, props.RequestCallBack, null, 'Click on - Forgot username')}} label={text('forgot.username.link.text')}></Link>
		}

		//Password input tag
		if (state.data.links['urn:vodafoneid:authenticate']) {
			passwordTag =
				<>
					<Input type={passwordType} className={errors.pwdClass} label={text("label.for.existing.password")} titleClassName={titleClassName} paddingClassName={paddingClassName} name="password" id={state.page_look=== "mva" ? "PASSWORD" : 'password'} minLength={10} placeholder={text("placeholder.for.existing.password")}
						handleChange={handleChange}
						handleBlur={handleBlur}
						handleKey={handleKeyPress}
						value={values.password}
						showIcon={true}
						iconClick={handleIconClick}
						iconClass={passwordIcon}
						errMsg={errors.pwdMsg}
						dataTestID={"PASSWORD"}
					></Input>
				</>
		}

		// MyVodafone guidance text
        if(appleWatchApp && state.data && state.data["hideRegistrationResetPwdLinkForAppleWatch"] ){
            myVodafoneGuidanceText = <div className={"help_text"}>{text("onenumber.guidance.text")}</div>;

            // text for the above:
            // If you have forgotten your password, or not yet registered, please go to myvodafone.pt and follow the instructions
        }

		//Forgot Passoword link
		if(appleWatchApp && state.data && state.data["hideRegistrationResetPwdLinkForAppleWatch"] ){
			// do not show
		}else{
			if( state.data.links['urn:vodafoneid:resetpassword'] ){
				const resetLink = state.data.links['urn:vodafoneid:resetpassword'];
				forgotPassword = <Link id="forgotPassword" handleLink={() => { trackPageEvent(getPageName(state.data.context), {eventCategory: "link", eventLabel: text('forgot.password.link.text') }), handlerLinks(resetLink, props.RequestCallBack, null, 'Click on - Forgot password')}} label={text('forgot.password.link.text')}></Link>
			}
		}

		//Remember my username checkbox
		/**
		 * https://cps.jira.agile.vodafone.com/browse/VIDP-17149
		 * Temporary removing remember me and and remembermyusername for gb and es
		 *
		 */
		const marketCode = window.config.market.locale.substring(3, 5).toLowerCase();
		if(marketCode != 'gb' && marketCode != 'es') {

			if(state.page_look !== "mva"){
				if (state.data.fields !== undefined && state.data.fields.rememberMyUsernameCheckbox !== undefined) {
					rememberMyUsernameCheckbox = <Checkbox id="remember_my_username" checked={isRememberUsername} handleCheckbox={(e) => handlerCheckbox(e)} disable={false} label={text(state.data.fields.rememberMyUsernameCheckbox[0].name)}></Checkbox>
				}
			}

			//Remember Me
			if (state.data.remember_me && state.data.remember_me.display === true) {
				rememberMeCheckbox = <Checkbox id="remember_me" checked={isRememberMe} handleCheckbox={(e) => handlerCheckbox(e)} disable={false} label={text('remember.me.checkbox.label')}></Checkbox>
			}
		}
		//Register Link
		//Forgot Passoword link
		if(appleWatchApp && state.data && state.data["hideRegistrationResetPwdLinkForAppleWatch"] ){
			// do not show
		}else{
			if (state.data.links['urn:vodafoneid:register']) {
				register = <Link id="register" 
					handleLink={() => {
							trackPageEvent(getPageName(state.data.context), {eventCategory: "link", eventLabel: text('register.link.text') })	
							handlerLinks(state.data.links['urn:vodafoneid:register'], props.RequestCallBack, null, 'Click on - Register link')
						}
					} 
					className={'text-left'} intro={text('register.link.intro.text')} label={text('register.link.text')}>
				</Link>
				}
		}

		//Or Seperate for DPA Pin
		if (state.data.fields !== undefined && state.data.fields.pin !== undefined) {
			OrSeperateTag = <OrSeperate></OrSeperate>

			dpaPin = <div className="section-otp-input">
						<InputOTP id="vf-input-otp" label={text('label.for.dpa_pin')} size={4} secured={true} otp={OTP} tabIndex={2} onHandleChange={(otp) => handleOTPChange(otp)} onHandleKeyDown={handleSubmit} />
					</div>;
		}
	}

	//Checkbox Handler
	function handlerCheckbox(e) {
		let desc = '';
		if (e.target.id === 'remember_me') {
			desc = 'Remember Me';
			setRememberMe(e.target.checked);
		} else {
			desc = 'Remember My Username'
			setRememberUsername(e.target.checked);
		}
		VFLogger.client({
			event_desc: desc,
			event_msg: 'Checked - ' + e.target.checked,
			page: state.data.context,
			fragment: state.data.fragment !== undefined ? state.data.fragment : '',
			market: state.data.nv !== undefined && state.data.nv === true ? 'NV' : market,
			service_name: state.data.context === undefined || state.data.context === '' ? '' : state.data.clientInfo === undefined || state.data.clientInfo === null ? '' : state.data.clientInfo.client_name
		});
	}

	//Register button handler
	function handlerLinks(link, callBack, target, desc) {
		const opco = ['IE'];
		if (market.countryCode !== undefined && opco.indexOf(market.countryCode) >= 0) {
			target = 'self';
		}
		let logInfo = {
			event_desc: desc,
			event_msg: 'URL: ' + link.href,
			page: state.data.context,
			fragment: state.data.fragment !== undefined ? state.data.fragment : '',
			market: state.data.nv !== undefined && state.data.nv === true ? 'NV' : market,
			service_name: state.data.context === undefined || state.data.context === '' ? '' : state.data.clientInfo === undefined || state.data.clientInfo === null ? '' : state.data.clientInfo.client_name
		};
		handleLink(link, callBack, target, logInfo);
	}

	//on Key Press Handler for Key Press on MSISDN input field
	function handleKeyPress(event) {
		if (event.target.id.toLowerCase() === 'password' && OTP.length > 1) {
			setOTP('');
			if(document.getElementById("otpInputWrap")){
				document.getElementById("otpInputWrap").firstElementChild.classList.remove("initial_state");
			}
		}
		var charCode = (event.which) ? event.which : event.keyCode;
		if (charCode !== 13 && charCode !== 32) {
			return true;
		} else if (charCode == 13) {
			handleSubmit(event);
		}
	}

	//Password Show/Hide Icon Click
	function handleIconClick(event) {
		if (event.target.id) {
			setPasswordIcon(event.target.className.indexOf('show') !== -1 ? 'hide' : 'show');
			setPasswordType(event.target.className.indexOf('show') !== -1 ? 'text' : 'password');
		}
	}

	function handleOTPChange(OTP){
		let otpValue = OTP;

		if( otpValue !== "" && otpValue.length === 4 ){
			setButtonType("primary");
		}else{
			setButtonType("primary disabled");
		}

		setOTP(otpValue);
	}

	const onSubmitWithReCAPTCHA = async () => {
		console.log("--- onSubmitWithReCAPTCHA ---");

		if( recaptchaRef.current ){

			// Submit with reCAPTCHA
			try {
				console.log("reCAPTCHA: get token");

				const token = await recaptchaRef.current.executeAsync();

				console.log("token: " + token);

				// reset reCAPTCHA
				recaptchaRef.current.reset();

				if( !token ){
					console.log("reCAPTCHA: get token failed");
				}

				submitForm( token );

			} catch (error) {
				console.log("ERROR: " + error);
			}
		}else{
			// Normal submit
			submitForm();
		}
	}

	//Form Submit function
	function submitForm(token) {

		if (OTP.length === 1) {
			if (values.password.length > 1) {
				values.password = '';
				setButtonType("primary disabled");
				setTitleClassName("hidden");
				setPaddingClassName("paddingTop20");
				setErrors({});
			}
		} else {

			setButtonType("primary spinner disabled");
			let submitLink = state.data.links['urn:vodafoneid:authenticate'];
			identifier = state.data.identifier.value === undefined ? values.identifier : identifier;
			let jsonObj = {
				identifier: identifier,
				identifierType: state.data.identifier.type
			}

			if (state.data["consent_status"]) {
				jsonObj["consentStatus"] = state.data["consent_status"];
				jsonObj["appId"] = state.data["app_id"];
			}

			if (state.data["remember_device"]) {
				jsonObj["rememberResourceId"] = state.data["remember_device"];
			}

			if (rememberMeCheckbox !== false && isRememberMe !== '') {
				jsonObj["remember_me"] = isRememberMe;
			}

			if (rememberMyUsernameCheckbox !== false && isRememberUsername !== '') {
				jsonObj["remember_my_username"] = isRememberUsername;
			}

			if (values.password !== "" && state.data.links["urn:vodafoneid:authenticate"] !== undefined) {
				submitLink = state.data.links['urn:vodafoneid:authenticate'];
				const encrypedPwd = encryptPassword(state.data.links['urn:vodafoneid:authenticate'], values.password)
				jsonObj["password"] = encrypedPwd;
			}

			// DPA PIN
			if (OTP.length > 1) {
				jsonObj["pin"] = OTP;
			}

			// Add reCAPTCHA token
			if( token !== undefined ){
				jsonObj["recaptcha_token"] = token;
			}

			const payload = JSON.stringify(jsonObj);

			let logInfo = {
				event_desc: 'Click on - Continue Button',
				event_msg: 'URL: ' + submitLink.href,
				resp_code: '',
				page: state.data.context,
				fragment: state.data.fragment !== undefined ? state.data.fragment : '',
				market: state.data.nv !== undefined && state.data.nv === true ? 'NV' : market,
				service_name: state.data.context === undefined || state.data.context === '' ? '' : state.data.clientInfo === undefined || state.data.clientInfo === null ? '' : state.data.clientInfo.client_name
			};

			ServiceRequest(submitLink, payload, props.RequestCallBack, null, logInfo);

			if( state.page_look === "mva" ){
				dispatch({
					type: SPLASH_LOAD,
					splash: true
				});
			}
		}
	}

	return (
		<>
			{/* {state.errorObj && state.errorObj.msg && (<FormErrorBox messageClass="form-error" error={text(state.errorObj.msg)} iconType={`icon_${state.errorObj.type}`} messageType={`message_${state.errorObj.type}`} />)} */}

			<div className="section-form">
				<div className="box">
					{identifierDisplay}
					{/* {errors.identifierMsg  && (<FormErrorBox error={errors.identifierMsg} iconType="icon_error" messageType="message_error" />)} */}
					{identifierTag}
					{/* {errors.pwdMsg  && (<FormErrorBox error={errors.pwdMsg} iconType="icon_error" messageType="message_error" />)} */}
					{forgotUsername}
					{passwordTag}
					{forgotPassword}
					{OrSeperateTag}
					{dpaPin}
					{rememberMyUsernameCheckbox}
					{rememberMeCheckbox}
				</div>
				{buttons}
				{register}
				{myVodafoneGuidanceText}

				{state.data && state.data.recaptcha &&
					<>
						<ReCAPTCHA
							ref={recaptchaRef}
							size="invisible"
							sitekey={state.data.recaptcha["data-sitekey"]}
							hl={(window.config && window.config.market && window.config.market.locale? window.config.market.locale.substring(0, 2) : "en_GB")}
						/>
					</>
				}
			</div>
		</>
	);
}

export default Authenticate;