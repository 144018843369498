import React, {
	useEffect,
	useContext,
	useState
} from "react";

import {
    StoreContext
} from "../../store/store";

// Action types
import {
	POPUP
} from "../../constants/actionTypes";

// Stying
import "./pop-up.scss";

const PopUpHeader = props => {
    const globalState = useContext(StoreContext);
	const { dispatch } = globalState;

    const closePopUp = () => {
        if(props.onCloseButtonClick){
            props.onCloseButtonClick();
        }
    }

	return (
        <div className="vf-popup-header-wrapper">
            <div className="vf-popup-header">
                <div className="close" onClick={closePopUp}></div>
                <div className="vf-popup-header-title">{props.children}</div>
                
            </div>
        </div>
    )
}

export default PopUpHeader;
